.HonorariosContainer {
    padding-bottom: 10px;
}

.accordion {
    border-radius: 4px;
}

.header {
    margin-top: 0;
    margin-bottom: 10px;
}