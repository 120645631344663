.field {
    height: 100%;
    width: 100%;
    background-color: #b0b0b0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image {
    width:25vw;
    height: auto;
}