.container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #b0b0b0;
}

a {
    position: absolute;
}

.alert {
    position: absolute;
    top: 10vh;
    margin: 0 10px;
}