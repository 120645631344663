.container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

header {
    background-color: #fff;
    padding: 20px;
    display: flex;
    justify-content: center;
    height: 80px;
}

.body {
    background: hsl(0, 0%, 98%);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    padding-bottom: 0;
    flex: 1
}

.skeleton {
    padding-top: 20px;
    width: 500px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

@media screen and (max-width: 650px) {
    .skeleton {
        width: 80%;
    }
}

.arrayContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.lowerContainer {
    min-width: 500px;
}

@media screen and (max-width: 550px) {
    .lowerContainer {
        min-width: 95%;
    }
}

.error {
    background-color: #b0b0b0;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
    width: 100%;
}

.alert {
    position: absolute;
    top: 10vh;
    margin: 0 10px;
    white-space: pre-line;
}

.appBar {
    height: 12.5%;
    padding-left: 5%;
    padding-right: 5%;
}

.logo {
    height: 100%;
}

.image {
    width:25vw;
    height: auto;
}

.toolbar {
    justify-content: space-between;
}

.limitDate {
    color: hsl(0, 0%, 20%);
}