.container {
    max-width: 500px;
}

.title {
    margin-bottom: 5px;
}

.list {
    padding-inline-start: 16px;
}

.LFPManager {
    margin-left: -32px;
}

.listItem {
    gap: 15px;
}