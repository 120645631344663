.validationContainer {
    padding: 20px 0;
}

.buttonWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px 0;
}

.accept:hover {
    background-color: #2e7d32;
}

.discard:hover {
    background-color: #d32f2f;
}
