.explanationContainer {
    display: flex;
    flex-direction: column;
    gap: 7px;
}

.explanation {
    flex: 1;
}

.sendButton {
    width: fit-content;
    align-self: flex-end;
    display: flex;
    gap: 5px;
}

.header {
    margin: 0;
}